/****************************************************/
// Capa de Servicios para el modulo de Visitas.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async reload() {
        this.header = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Token-Google-Authenticator': sessionStorage.getItem('2fa')
        };
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/visits");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/visits/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(device) {
        try {
            let formData = new FormData();
            formData.append("device", device);
            let response = await axios.post(this.#baseUrl + "/visits", formData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    // Pushes posts to the server when called.
    updateRecord(id, device) {
        this.reload();
        let result = null;
        // console.log('Updating Record: ', formData);
        let formData = new FormData();
        formData.append("id", id);
        formData.append("device", device);
        axios.put(`${this.#baseUrl}/visits/${id}`, formData, {
          headers: this.header
        })
            .then(response => {
                let data = response.data;
                result = data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    deleteRecord(id) {
        let result = null;
        this.reload();
        // console.log('Deleting Record: %s ', id);
        axios.delete(`${this.#baseUrl}/visits/${id}`, {
          headers: this.header
        })
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        return valueError;
    }

}